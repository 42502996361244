import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { lazy } from 'react';

const Home = lazy(() => import('./Home'));
const Lista = lazy(() => import('./Lista'));
const Wolontariat = lazy(() => import('./Wolontariat'));
const Kalendarz = lazy(() => import('./Kalendarz'));
const NonExistingPage = lazy(() => import('./NonExistingPage'));
// import Home from './Home';
// import Lista from './Lista';
// import Wolontariat from './Wolontariat';

function Router() {
	return (
		<BrowserRouter>
			<Routes>
				{/* <Route path='/' element={<Home />} /> */}
				<Route path='/' element={<Lista />} />
				<Route path='/lista' element={<Lista />} />
				<Route path='/wolontariat' element={<Wolontariat />} />
				<Route path='/volunteer' element={<Wolontariat />} />
				<Route path='/kalendarz' element={<Kalendarz />} />
				<Route path='/*' element={<NonExistingPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
