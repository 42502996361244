import React from 'react';
import Router from './components/Router';
import Navbar from './components/Navbar';
// import './App.css';

function App() {
	return (
		<div className='App'>
			<Navbar />
			<Router />
		</div>
	);
}

export default App;
