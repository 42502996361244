import React from 'react';
// import '../assets/B.jpg';

function Navbar() {
	return (
		<nav className='navbar navbar-expand-lg bg-primary z-2'>
			<div className='container-fluid'>
				<a className='navbar-brand' href='/'>
					<img
						src='https://recedodziela.pl/backend/assets/logo.png'
						alt='Logo'
						// width='30'
						height='30'
						className='d-inline-block align-text-top'
					/>
					<div className='me-2 d-inline' />
					Ręce do dzieła!
				</a>
				<button
					className='navbar-toggler position-absolute'
					style={{ right: '10px', top: '10px' }}
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarNav'
					aria-controls='navbarNav'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div className='me-3' />
				<div className='collapse navbar-collapse' id='navbarNav'>
					<ul className='navbar-nav'>
						<li className='nav-item'>
							{/* active */}
							<a className='nav-link disabled' aria-current='page' href='/'>
								Strona Główna
							</a>
						</li>
						<li className='nav-item'>
							<a className='nav-link' href='/lista'>
								Lista
							</a>
						</li>
						<li className='nav-item'>
							<a className='nav-link' href='/kalendarz'>
								Kalendarz wolontariatów
							</a>
						</li>
						{/* <li className='nav-item'>
							<a className='nav-link text-white' href='/wolontariat?id=1'>
								WOLONTARIAT_DEV
							</a>
						</li> */}
					</ul>
				</div>
			</div>
		</nav>
	);
}

export default Navbar;
